<template>
  <div>
    <!-- 校园达人 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.account" clearable placeholder="请输入学号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.idCard" clearable placeholder="请输入身份证号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" icon="el-icon-search"  @click="refreshB()">搜索</el-button>
      </el-form-item>
      <el-form-item class="fr">
        <div class="btn-wrap cfx">
          <!-- <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false)">添加</el-button> -->
          <!-- <el-button type="warning" size="small" icon="el-icon-position" @click="handleOut" v-if="OA.includes('user:userStatus:derive')">导出</el-button> -->
        </div>
      </el-form-item>
    </el-form>
    <div class="content-wrap">
      <!--列表-->
      <el-table :data="userList" border stripe style="width: 100%"
        ref="table">
        <el-table-column sortable="custom" prop="extend.userName" show-overflow-tooltip label="姓名"></el-table-column>
        <el-table-column sortable="custom" prop="extend.schoolName" show-overflow-tooltip label="学校"></el-table-column>
        <el-table-column sortable="custom" prop="fade" show-overflow-tooltip label="学院"></el-table-column>
        <el-table-column sortable="custom" prop="major" show-overflow-tooltip label="专业"></el-table-column>
        <el-table-column sortable="custom" prop="account" show-overflow-tooltip label="学号"></el-table-column>
        <el-table-column sortable="custom" prop="sex" show-overflow-tooltip label="性别">
          <template slot-scope="scope">
            <span v-if="scope.row.sex==1">男</span>
            <span v-if="scope.row.sex==2">女</span>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="authenSta" show-overflow-tooltip label="认证状态">
          <template slot-scope="scope">
            <span v-if="scope.row.authenSta==0">未认证</span>
            <span v-if="scope.row.authenSta==1">待审核</span>
            <span v-if="scope.row.authenSta==2">已认证</span>
            <span v-if="scope.row.authenSta==3">不通过</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <!-- <el-button size="small" class="handle" :disabled="!storeList.length" @click="handleAgain(userDetail.id, userDetail.mealId)">复通</el-button> -->
        <el-button size="small" class="handle" :disabled="!userList.length" @click="handleAgain(userDetail.id, userDetail.mealId)">设为校园达人</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import PagedTable from "@/components/common/PagedTable";
// import BaseImport from "@/components/common/BaseImport";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from "@/common/utils";
export default {
  name: "appUser",
  components: {
    PagedTable,
    AreaTree,
    // BaseImport
  },
  mixins: [pageMixin],
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 1,
        account: '',
        idCard: null,
      },
      userList: [],
      userDetail: {
        id:null,
        extend: {},
      },
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("appUser", ["storeList", "storeTotal"]),
  },
  methods: {
    ...mapActions("appUser",["membersAppGetTargetUserList", "membersAppGrantTalent"]),
    async refreshB(){ //列表查询
      this.tLoading = true
      this.savePageParams({
        pageName: this.pageName,
        params: this.queryParams
      });
      try {
        let res =  await this.membersAppGetTargetUserList(this.queryParams)
        this.userList = res
        this.userDetail = res[0]
        console.log(this.userDetail)
      }catch(e){
        log(e)
      }finally {
        this.tLoading = false;
      }
    },
    /** 设为校园达人 */
    handleAgain() {
      this.$confirm('此操作将设为校园达人, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async () => {
          try{
            let res = await this.membersAppGrantTalent({id: this.userDetail.id})
            this.$message({
              type: 'success',
              message: '新增校园达人成功'
            });
          }catch (error){
            this.$message.error("新增校园达人失败")
          }finally{
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        })
    },
    submitOpen() {},
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
    mergeObject(this.queryParams, this.getPageParams(this.$options.name))
  },
};
</script>
